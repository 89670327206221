<template>
  <div>
    <div>
      <el-image fit="contain" :src="abouts" style="width:100%;" />
    </div>
    <el-breadcrumb separator="/" style="margin-left:10px; margin-top:10px">
      <el-breadcrumb-item :to="{ path: '/' }">{{
        $t('Nav.home')
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t('Nav.abus') }} </el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div
        style="color:#4a58ff; font-weight:bold;margin-top:20px;margin-bottom:10px;font-size:20px"
      >
        {{ $t('Nav.qiyegaishu') }}
      </div>
      <div style="text-indent:2em; text-align:left;margin-bottom:20px">
        {{ $t('Nav.qiyegaishuText04') }}
      </div>
      <div style="text-indent:2em; text-align:left;margin-bottom:20px">
        {{ $t('Nav.qiyegaishuText05') }}
      </div>
      <div style="text-indent:2em; text-align:left;margin-bottom:20px">
        {{ $t('Nav.qiyegaishuText06') }}
      </div>
      <div><el-image fit="contain" :src="abouts_01" style="width:100%;" /></div>
    </div>
    <div>
      <div
        style="color:#4a58ff; font-weight:bold;margin-top:20px;margin-bottom:10px;font-size:20px"
      >
        {{ $t('Nav.qiyeyuanjing') }}
      </div>
      <div style="text-indent:2em; text-align:left;margin-bottom:20px">
        {{ $t('Nav.qiyegaishuText02') }}
      </div>
      <div><el-image fit="contain" :src="abouts_02" style="width:100%;" /></div>
    </div>
    <div>
      <div
        style="color:#4a58ff; font-weight:bold;margin-top:20px;margin-bottom:10px;font-size:20px"
      >
        {{ $t('Nav.zhuanyezizhi') }}
      </div>
      <div style="text-indent:2em; text-align:left;margin-bottom:20px">
        {{ $t('Nav.qiyegaishuText03') }}
      </div>
      <div><el-image fit="contain" :src="abouts_03" style="width:100%;" /></div>
    </div>
    <div style="clear: both; overflow: hidden;margin-bottom:20px;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'mobileaboutus',
  components: {
    // HelloWorld
  },
  data() {
    return {
      abouts: require('../../assets/index/05.jpg'),
      abouts_01: require('../../assets/mobile/02-img1@3x.png'),
      abouts_02: require('../../assets/mobile/02-img2@3x.png'),
      abouts_03: require('../../assets/mobile/02-img3@3x.png'),
    }
  },
  methods: {},
}
</script>

<style></style>
